/**
 * (c) David Giesemann
 * info@david-giesemann.de
 */

import "./index.scss"

export default class CustomScrollbar{
    #settings = {
        scrollbarBackground: "#e9e9e9",
        scrollbarWidth: "25%",
        scrollbarHeight: ".75rem",
        scrollbarRadius: ".75rem",
        scrollBarColor: "#0ba3b2"
    }

    #element
    #customScrollcontainer = document.createElement("div")
    #customScrollbar = document.createElement("div")

    #boxContentRatio = 1
    #isDragged = false
    #dragStart = 0
    #scrollStart = 0

    constructor(element,options = {}) {
        Object.assign(this.#settings,options)

        this.#element = element
        this.#element.classList.add("has-custom-scrollbar")

        const childrenWidth = [...this.#element.children]
            .map(c => {
                const style = window.getComputedStyle(c)
                const w = c.getBoundingClientRect().width
                const m = parseFloat(style.marginLeft) + parseFloat(style.marginRight)
                const p = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
                const b = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth)

                return (w + m)
            })
            .reduce((a,b) => a+b,0)
        this.#boxContentRatio = this.#element.offsetWidth/childrenWidth

        this.#customScrollcontainer.classList.add("custom-scroll")
        this.#customScrollcontainer.style.backgroundColor = this.#settings.scrollbarBackground
        this.#customScrollcontainer.style.borderRadius = this.#settings.scrollbarRadius
        this.#customScrollcontainer.appendChild(this.#customScrollbar)

        this.#customScrollbar.classList.add("custom-scroll--scrollbar")
        this.#customScrollbar.style.backgroundColor = this.#settings.scrollBarColor
        this.#customScrollbar.style.height = this.#settings.scrollbarHeight
        this.#customScrollbar.style.width = `${this.#boxContentRatio * 100}%`
        this.#customScrollbar.style.borderRadius = this.#settings.scrollbarRadius

        this.#element.after(this.#customScrollcontainer)

        this.#element.addEventListener("scroll",() => this.onScroll(),{passive:true})

        this.#customScrollbar.addEventListener("mousedown", e => this.dragStart(e))
        this.#customScrollbar.addEventListener("touchstart", e => this.dragStart(e))

        document.addEventListener("mouseup", () => this.dragEnd())
        document.addEventListener("touchend", () => this.dragEnd())

        document.addEventListener("mousemove", e => this.onDrag(e))
        document.addEventListener("touchmove", e => this.onDrag(e))

        this.onScroll()
    }

    get scrollContainer(){
        return this.#customScrollcontainer
    }

    dragStart(e){
        this.#isDragged = true
        this.#dragStart = e.clientX || e.touches[0].clientX
        //this.#scrollStart = this.#customScrollbar.getBoundingClientRect().left - this.#customScrollcontainer.getBoundingClientRect().left
        this.#scrollStart = this.#element.scrollLeft * this.#boxContentRatio
    }

    dragEnd(){ this.#isDragged = false }

    onDrag(e){
        if(!this.#isDragged) return;

        e.preventDefault()

        const xPosition = e.clientX || e.touches[0].clientX

        this.#element.scrollTo({left: (xPosition + this.#scrollStart - this.#dragStart) / this.#boxContentRatio})
    }

    onScroll(){
        this.#customScrollbar.style.transform = `translateX(${this.#element.scrollLeft * this.#boxContentRatio}px)`
    }
}
