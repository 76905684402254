/**
 * (c) David Giesemann
 * info@david-giesemann.de
 */

import "./index.scss"

import CustomScrollbar from "./modules/CustomScrollbar";

let scroller = null

const init = () => {
    const element = document.querySelector("#manufacturers")

    if(!element) return;

    if(scroller) scroller.scrollContainer.remove()

    if(element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) scroller = new CustomScrollbar(element,{
        scrollBarColor: "#cc0000"
    })
}

window.addEventListener("DOMContentLoaded",init)
window.addEventListener("resize",init)
